@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .input-style {
    @apply block py-2 px-4 w-1/2 text-center rounded-xl mb-4;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
