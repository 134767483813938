@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;500&family=Markazi+Text:wght@500;700&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Karla';
  height: 100vh;
  font-size: 16px;
}

h1 {
  font-family: 'Markazi';
  font-weight: 500;
}

h2 {
  font-family: 'Markazi';
  font-weight: bold;
  font-size: 1.5rem;
  text-transform: uppercase;
  text-align: center;
}

h3 {
  font-family: 'Markazi';
  font-weight: 500;
  font-size: 1.25rem;
  text-align: center;
  text-transform: uppercase;
}
